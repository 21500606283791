<template>
    <div class="hero" :style="{ backgroundImage: `url(${location + imageConf.image})` }"
            >
            <div class="block-head container-head--xs" v-if="showButtonPhotoProfile">
                <!-- <div>
                    <span class="icon-user medium"></span>
                </div> -->

                <div class="image_profile_div jhon">                
                    <template v-if="dataShop.photo">
                    <img class="image_profile" :src="`${baseURLStatic}` + dataShop.photo" alt="Club de Sastreria Barrington" />
                    </template>
                    <template v-else>                        
                    <img class="image_profile_empty" src="/images/profile_empty.png" alt="Club de Sastreria Barrington" />
                    </template>
                </div>
                <div class="text-left">
                    <h1>¡Hola {{ dataShop.name }}!</h1>
                    <h3 v-if="dataShop.country || dataShop.department"><strong>{{ dataShop.country }} - {{ dataShop.department }}</strong></h3>
                    <h3>{{ dataShop.address || "&nbsp;" }}</h3>
                    <router-link :to="{ name: 'ShopRegisterUser' }">
                        <button class="cta cta--lighten cta-status"><strong>Registrar nuevo usuario</strong></button>
                    </router-link>
                    
                </div>
            </div>
        </div>
</template>

<script>
//style="background-image: linear-gradient(rgba(0 0 0 / 0.3), rgba(0 0 0 / 0.3));"


import { ref } from "vue";
import API from "@/api"

async function mounted(){
    this.getImageMain();
}

export default {
    name: "HeadShop",
    mounted,
    props: {
        data: Object,
        showButtonPhotoProfile: {
            type: Boolean,
            default: true
        }
    },
    setup() {
        const baseURLStatic = API.defaults.baseURLStatic

        const dataShop = ref({
            name: "",
            country: "",
            department: "",
            address: ""
        });

        const imageConf = ref({})
        const location = baseURLStatic//'https://services.barrington.com.pe';

        const getDataShop = () => ({
            
            name: localStorage.getItem('name'),
            country: localStorage.getItem('country'),
            department: localStorage.getItem('department'),
            address: localStorage.getItem('address'),
            photo: localStorage.getItem('photo')
        })

        const getImageMain = ()=>
        {
            API.get("/api/head-global-page-internal")
                .then(response => {
                    imageConf.value = response.data[0]
                })
                .catch(error => {
                })
        }
        
        dataShop.value = getDataShop()


        return { dataShop, imageConf, location, getImageMain, baseURLStatic }
    }
}
</script>