<template>
    <main>          
        <loading :active="isLoading"
                 :can-cancel="false"
                 :on-cancel="onCancel"                 
                 :is-full-page="fullPage"/>    
        <HeadShop />        
        <section class="section pt-0 my-status">
            <div class="container">
                <p class="breadcrumb"><a href="/"><span class="icon-home-outline"></span></a> / <span>Mi Tienda</span></p>
                <h2><a href="#" @click="router.go(-1)"><img src="/images/arrowleft.svg" alt="Club de Sastreria Barrington"></a> Mi Tienda</h2>
                <div class="page-content">
                    <div class="tabbed">
                        <input type="radio" id="tab1" name="css-tabs" checked>
                        <input type="radio" id="tab2" name="css-tabs">
                        <ul class="tabs">
                            <li class="tab"><label for="tab1">Información personal</label></li>
                            <li class="tab"><label for="tab2">Cambio de contraseña</label></li>
                        </ul>	
                        <div class="tab-content">	                
                            <div class="py-lg pt-0">
                                <p>
                                    Recuerda, mantener tus datos actualizados nos ayuda a conocerte mejor y brinda un mejor servicio.                        
                                </p>
                            </div>
                            <!-- jkjjkjkjjkjkjjkjk -->
                            <form action="" class="form container container--xs" @submit.prevent="onSubmit">
                                <input type="hidden" >
                                <div class="form__control">
                                    <label class="form__label">Cambiar imagen de mi cuenta:</label>

                                    <input type="file" accept="image/*" class="form__field" @change="uploadImage($event)">

                                </div>
                                <p class="form__alert">
                                        
                                </p>
                                <div class="form__control">
                                    <label class="form__label">Razón Social :</label>
                                    <input type="text" class="form__field" v-model="dataShop.value.shop_that_register.business_name">
                                </div>
                                <p class="form__alert">
                                        
                                </p>
                                
                                <div class="form__control">
                                    <label class="form__label">Nombre Comercial :</label>
                                    <input type="text" class="form__field" placeholder="" v-model="dataShop.value.shop_that_register.tradename">
                                </div>
                                <p class="form__alert">
                                        
                                </p>
                                <div class="form__control">
                                    <label class="form__label">RUC :</label>
                                    <input type="text" class="form__field" placeholder="" v-model="dataShop.value.shop_that_register.ruc">
                                </div>
                                <p class="form__alert">
                                        
                                </p>
                                <div class="form__control">
                                    <label class="form__label">Represeentante legal o contacto de tienda :</label>
                                    <input type="text" class="form__field" placeholder="Nombres y apellidos completos" v-model="dataShop.value.shop_that_register.legal_representative">
                                </div>
                                <p class="form__alert">
                                        
                                </p>
                                <div class="form__control">
                                    <label class="form__label">Teléfono de contacto :</label>
                                    <input type="text" class="form__field" placeholder="" v-model="dataShop.value.shop_that_register.phone">
                                </div>
                                <p class="form__alert">
                                        
                                </p>

                                <div class="form__control">
                                    <label class="form__label form__label--error">Móvil / Whastapp :</label>
                                    <div class="form__control__row">
                                        <div>
                                            <select  class="form__field form__field--select">
                                                <option value="54">Argentina (54+)</option>
                                                <option value="591">Bolivia (591+)</option>
                                                <option value="56">Chile (56+)</option>
                                                <option value="51">Perú (51+)</option>
                                            </select>
                                        </div>
                                        <div>
                                            <input type="tel" class="form__field" placeholder="" v-model="dataShop.value.shop_that_register.phone_movil">
                                        </div>
                                        
                                    </div>
                                    <p class="form__alert" >
                                        
                                    </p>
                                </div>
                            
                                
                                <div class="form__control">
                                    <label class="form__label form__label--error">Email :</label>
                                    <input type="email" class="form__field" v-model="dataShop.value.email" disabled>
                                    <p class="form__alert" >
                                        
                                    </p>
                                        
                                </div>
                                <div class="form__control">
                                    <label class="form__label form__label--error">Pais :</label>
                                    <select ref="jhon" class="form__field form__field--select" v-model="dataShop.value.country_shop" @change="getDepartment({param1:$event})">
                                        <option value=null disabled>Seleccione Pais</option>
                                        <option :value="v.id" v-for="(v, k) in dataCountry" :key="k">{{ v.name }}</option>
                                    </select>
                                    <p class="form__alert" >
                                        
                                    </p>                            
                                </div>
                                <div class="form__control">
                                    <label class="form__label form__label--error">Departamento :</label>
                                    <select class="form__field form__field--select" v-model="dataShop.value.department_shop" @change="getProvince({param1:$event})">
                                        <option value=null disabled>Seleccione Departamento</option>
                                        <option :value="v[0]" v-for="(v, k) in dataDepartment" :key="k">{{ v[1] }}</option>
                                    </select>
                                    <p class="form__alert" >
                                        
                                    </p>                            
                                </div>
                                <div class="form__control">
                                    <label class="form__label form__label--error">Provincia :</label>
                                    <select class="form__field form__field--select" v-model="dataShop.value.province_shop" @change="getDistrict({param1:$event})">
                                        <option value=null disabled>Seleccione Provincia</option>
                                        <option :value="v[0]" v-for="(v, k) in dataProvince" :key="k">{{ v[1] }}</option>
                                        
                                    </select>
                                    <p class="form__alert" >
                                        
                                    </p>                            
                                </div>
                                <div class="form__control">
                                    <label class="form__label form__label--error">Distrito :</label>
                                    <select class="form__field form__field--select" v-model="dataShop.value.shop_that_register.ubigeo">
                                        <option value=null disabled>Seleccione Distito</option>
                                        <option :value="v[0]" v-for="(v, k) in dataDistrict" :key="k">{{ v[1] }}</option>
                                    </select>
                                    <p class="form__alert" >
                                        
                                    </p>                            
                                </div>
                                <div class="form__control">
                                    <label class="form__label form__label--error">¿Cuenta con letrero Barrington? :</label>
                                    <select class="form__field form__field--select" v-model="dataShop.value.shop_that_register.sign_barrington">
                                        <option value="" disabled>Seleccione</option>
                                        <option value="false">No</option>
                                        <option value="true">Si</option>
                                        
                                    </select>
                                    <p class="form__alert" >
                                        
                                    </p>                            
                                </div>
                                <div class="form__control">
                                    <label class="form__label">Dirección :</label>
                                    <input type="text" class="form__field" placeholder="" v-model="dataShop.value.shop_that_register.address">
                                </div>
                                <p class="form__alert">
                                        
                                </p>        
                                

                                <button class="cta" @click="updateShop()">Guardar</button>

                            
                            </form>                
                        </div>
                        <div class="tab-content">	
                            <form action="" class="form container container--xs" @submit.prevent="formChangePasswordSubmit">
                                
                                <p>
                                    Recuerda mantener tus datos actualizados nos ayuda a conocerte mejor y brindarte un 
                                    mejor servicio. Recuerda, de completar la totalidad de información de tu pérfil recibiraras un bono 
                                    especial de puntos.
                                </p>                                
                                
                                <div class="form__control">
                                    <label class="form__label">Contraseña actual:</label>            
                                    <input type="password" class="form__field" placeholder="" v-model="formChangePassword.password">
                                </div>
                                <p class="form__alert" v-if="formChangePasswordError.errors.password">
                                    {{ formChangePasswordError.errors.password[0] }}                                        
                                </p>                                
                                <div class="form__control">
                                    <label class="form__label">Nueva Contraseña:</label>            
                                    <input type="password" class="form__field" placeholder="" v-model="formChangePassword.password1">
                                </div>
                                <p class="form__alert" v-if="formChangePasswordError.errors.password1">
                                    {{ formChangePasswordError.errors.password1[0] }}
                                </p>
                                <div class="form__control">
                                    <label class="form__label">Nueva Contraseña:</label>            
                                    <input type="password" class="form__field" placeholder="" v-model="formChangePassword.password2">
                                </div>
                                <p class="form__alert" v-if="formChangePasswordError.errors.password2">
                                    {{ formChangePasswordError.errors.password2[0] }}
                                </p>
                                <p class="form__alert" v-if="formChangePasswordError.errors.non_field_errors">
                                    {{ formChangePasswordError.errors.non_field_errors[0] }}
                                </p>
                                
                                                                       
                                <button class="cta" >Guardar</button>

                            </form>      
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    </main>       

</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { ref, computed, reactive, watch } from "vue";
import store from '@/store'
import { useStore } from 'vuex'
import router from "@/router"
import Swal from 'sweetalert2'
import API from "@/api";
import LineLevel from "@/components/LineLevel";
import Exchange from "@/components/Exchange";
import HeadShop from "@/views/private/shop/components/HeadShop";
import { mapMutations, mapActions, mapState } from 'vuex';

export default {
    name: 'MyShop',
    components: {
        HeadShop,
        Loading,
    },
    setup(){
        var isLoading = ref(false)
        const fullPage = true

        const formChangePassword = ref({
            password: "",
            password1: "",
            password2: ""
        })
        const formChangePasswordSuccessful = ref(false)           
        const formChangePasswordError = ref({errors:{}})

        const dataShop = reactive({ shop_that_register: {} })       
        const dataCountry = ref([])
        const dataDepartment = ref([])
        const dataProvince = ref([])
        const dataDistrict = ref([])
        /* datos del store y manejados en el setup, se tuvo que usar reactive, la data es un un objeto */
        dataShop.value = computed(() => store.state.user)

        const formChangePasswordSubmit = () => {        
            isLoading.value = true    
            API.put("/api/change-password/" + store.state.dataToken.id, formChangePassword.value)
                .then(response => {
                    formChangePasswordSuccessful.value = true
                    isLoading.value = false
                    Swal.fire({
                        title: 'Contraseña cambiada',
                        //text: 'Do you want to continue',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                })
                .catch(error => {
                    isLoading.value = false
                    formChangePasswordError.value = error.response.data
                })
        }

        const uploadImage = (event, type, index) => { 
            //debugger
            dataShop.value.shop_that_register.photo = event.target.files[0]
        }

        const updateShop = () => {
            
            isLoading.value = true
            const form_data = new FormData();                    
            for ( var key in dataShop.value.shop_that_register ) {
                //debugger
                console.log(key)
                form_data.append(key, dataShop.value.shop_that_register[key]);
            }
            //debugger

            //API.put("/api/shop/" + dataShop.value.shop_that_register.id, dataShop.value.shop_that_register)            
            API.put("/api/shop/" + dataShop.value.shop_that_register.id, form_data)
            .then(response => {
                isLoading.value = false
                console.log("updated!")
                Swal.fire({
                        title: 'Datos actualizados',                        
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
            })
            .catch(error => {
                isLoading.value = false
                console.log(error.response.data)
            })
        }

        
        const getCountry = () => {
            API.get('/api/ubigeo/country')
                .then(response => {
                    dataCountry.value = response.data                   
             /*        return dataCountry.value */
                })
        }
        const getDepartment = ({param1, param2}={}) => {
            //debugger
            let value = ""
            if (param1) {
                value = param1.target.value
            } else if (param2) {
                value = param2
            }

            API.get("/api/ubigeo/" + value)
                .then(response => {
                    dataDepartment.value = response.data

                })
        }

        const getProvince = ({param1, param2}={}) => {
            //debugger
            let value = ""
            if (param1) {
                value = param1.target.value
            } else if (param2) {
                value = param2
            }
            
            API.get("/api/ubigeo/" + value)
                .then(response => {
                    dataProvince.value = response.data

                })
        }

        const getDistrict = ({param1, param2 } ={}) => {
            //debugger
            let value = ""
            if (param1) {
                value = param1.target.value
            } else if (param2) {
                value = param2
            }            
            API.get("/api/ubigeo/" + value)
                .then(response => {
                    dataDistrict.value = response.data

                })
        }
                    
        watch(() => {            
            if (dataShop.value.country_shop){
                getDepartment({param2:dataShop.value.country_shop})
            }

        })
        watch(() => {
            if (dataShop.value.department_shop) {
                getProvince({param2:dataShop.value.department_shop})
            }
        })
        watch(() => {
            if (dataShop.value.province_shop) {
                getDistrict({param2:dataShop.value.province_shop})
            }
        })                
            
        return {
            isLoading,
            fullPage,   
            formChangePasswordSubmit,
            formChangePasswordError,
            formChangePasswordSuccessful,
            formChangePassword,
            uploadImage,
            router,
            getCountry,
            getDepartment,
            getProvince,
            getDistrict,
            dataDepartment,
            dataProvince,
            dataDistrict,
            dataCountry,
            updateShop,
            dataShop, 
            store, 
            ...mapActions(['getUser']),
        }
    },
    async mounted() {
        this.getUser(store.state.dataToken.id)
        this.getCountry()        
        
    },
    
    
    
    
}
</script>


<style scoped>
    
.page-content {
	max-width: 700px; 
	margin: 32px auto; 
	/* padding: 32px;  */
	background: #fff;
}
a {
	color: #21D4FD; 
	transition: all 0.3s;
}
a:hover {
	color: #B721FF;
}

.tabbed {
	overflow-x: hidden; /* so we could easily hide the radio inputs */
	margin: 32px 0;
	padding-bottom: 16px;
	border-bottom: 1px solid #ccc;
}

.tabbed [type="radio"] {
	/* hiding the inputs */
	display: none;
}

.tabs {
	display: flex;
	align-items: stretch;
	list-style: none;
	padding: 0;
	border-bottom: 1px solid #ccc;
}
.tab > label {
	display: block;
	margin-bottom: -1px;
	padding: 12px 15px;
	border: 1px solid #ccc;
	background: #eee;
	color: #666;
	font-size: 12px; 
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1px;
	cursor: pointer;	
	transition: all 0.3s;
}
.tab:hover label {
	border-top-color: #333;
	color: #333;
}

.tab-content {
	display: none;
	color: #777;
}

/* As we cannot replace the numbers with variables or calls to element properties, the number of this selector parts is our tab count limit */
.tabbed [type="radio"]:nth-of-type(1):checked ~ .tabs .tab:nth-of-type(1) label,
.tabbed [type="radio"]:nth-of-type(2):checked ~ .tabs .tab:nth-of-type(2) label,
.tabbed [type="radio"]:nth-of-type(3):checked ~ .tabs .tab:nth-of-type(3) label,
.tabbed [type="radio"]:nth-of-type(4):checked ~ .tabs .tab:nth-of-type(4) label,
.tabbed [type="radio"]:nth-of-type(5):checked ~ .tabs .tab:nth-of-type(5) label {
	border-bottom-color: #fff;
	/* border-top-color: #B721FF; */
	background: #fff;
	color: #222;
}

.tabbed [type="radio"]:nth-of-type(1):checked ~ .tab-content:nth-of-type(1),
.tabbed [type="radio"]:nth-of-type(2):checked ~ .tab-content:nth-of-type(2),
.tabbed [type="radio"]:nth-of-type(3):checked ~ .tab-content:nth-of-type(3),
.tabbed [type="radio"]:nth-of-type(4):checked ~ .tab-content:nth-of-type(4) {
	display: block;
}
</style>